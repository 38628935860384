<script setup>
import Header from '~/components/Header.vue'
import ErrorModal from '~/components/ErrorModal.vue'
import Notifications from '~/components/Notifications.vue'
import Loader from '~/components/Loader.vue'
import '~/assets/scss/common.scss'
import { onMounted } from 'vue'

const route = useRoute()
const config = useRuntimeConfig()
const { user, loggedIn } = useAuth()

useHead({
    link: [
        { 
            rel: 'stylesheet', 
            href: () => import(`~/assets/scss/base.scss`)
        }
    ]
})

onMounted(() => {
    if (typeof ym === 'undefined') return

    ym(config.public.YM_ID, 'userParams', {
        project_id: route.params.project_id,
        team_id: user.user.team_id,
        email: user.user.email,
        name: user.user.name,
        UserID: user.user.id
    })
})
</script>

<template>
    <div>
        <component :is="'style'">
            :root {
                --primary-color: #FC9E5E;
                --grey-color: #F7F7F9;
                --text-color: #14223D;
                --text-grey-color: #92A2C0;
                --border-grey-color: #ECECED;
                --gradient-from-color: #F48032;
                --gradient-to-color: #F7B189;
                --gradient-2-from-color: #FFA996;
                --gradient-2-to-color: #FFD3B6;
            }
        </component>
        <div class="w-100">
            <Header/>
            <div class="background">
                <div class="container">
                    <div>
                        <slot/>
                    </div>
                    <ErrorModal/>
                    <Notifications/>
                    <Loader/>
                </div>
            </div>
        </div>
    </div>
</template>

